import React, { ReactNode } from "react";

export interface SectionSubtitleProps {
  className?: string;
  children: ReactNode;
}

export const SectionSubtitle = (props: SectionSubtitleProps) => {
  const { className = "", children } = props;
  return (
    <div className={`${className} inline-flex flex-col space-y-2`}>
      <h4 className="text-primary text-2xl">{children}</h4>
      <div className="underline w-full h-0.5 bg-primary"></div>
    </div>
  );
};
