import React, { Fragment, useMemo, useState } from "react";
import { Button } from "../Shared/Button/Button";
import { InstagramLink } from "./components/InstagramLink";
import { useInstagramLinks } from "./hooks/useInstagramLinks";

export interface InstagramLinksProps {
  className?: string;
}

const POSTS_PER_PAGE = 24;

export const InstagramLinks = (props: InstagramLinksProps) => {
  const { className = "" } = props;
  const links = useInstagramLinks();
  const [numberOfPostsToShow, setNumberOfPostsToShow] = useState(
    POSTS_PER_PAGE
  );

  const postsToShow = useMemo(() => links.slice(0, numberOfPostsToShow), [
    numberOfPostsToShow,
  ]);

  return (
    <Fragment>
      <section
        className={`${className} grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-8`}
      >
        {postsToShow.map((link) => (
          <InstagramLink key={link.url} {...link} />
        ))}
      </section>
      <div className="actions flex justify-center">
        <Button
          disabled={links.length <= numberOfPostsToShow}
          onClick={() =>
            setNumberOfPostsToShow(numberOfPostsToShow + POSTS_PER_PAGE)
          }
        >
          Načítať viac
        </Button>
      </div>
    </Fragment>
  );
};
