import React, { ReactNode } from "react";

export interface SectionDescriptionProps {
  className?: string;
  children: ReactNode;
}

export const SectionDescription = (props: SectionDescriptionProps) => {
  const { className = "", children } = props;
  return (
    <h5 className={`${className} font-medium text-lg lg:text-2xl`}>
      {children}
    </h5>
  );
};
