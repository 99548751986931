import { graphql, useStaticQuery } from "gatsby";

export interface IInstagramLink {
  url: string;
  image: any;
}

export const useInstagramLinks = (): IInstagramLink[] => {
  const {
    allStrapiInstagramPost: { nodes },
  } = useStaticQuery(graphql`
    query useInstagramLinks {
      allStrapiInstagramPost(sort: { fields: date, order: DESC }, limit: 1000) {
        nodes {
          url
          image {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    }
  `);

  return nodes;
};
