import React from "react";
import { InstagramLinks } from "../components/InstagramLinks/InstagramLinks";
import { Container } from "../components/Shared/Container/Container";
import Layout from "../components/Shared/Layout/Layout";
import { SectionHeader } from "../components/Shared/SectionHeader/SectionHeader";

export interface InstagramLinksPageProps {
  className?: string;
}

export const InstagramLinksPage = (props: InstagramLinksPageProps) => {
  const { className = "" } = props;
  return (
    <Layout>
      <Container className="py-16 space-y-16">
        <SectionHeader
          title="TU NÁJDETE VŠETKY
				INSTAGRAMOVÉ LINKY"
          subtitle="Instagramové odkazy"
        />
        <InstagramLinks />
      </Container>
    </Layout>
  );
};

export default InstagramLinksPage;
