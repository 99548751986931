import { Box, BoxProps } from "@chakra-ui/react";
import React, { ReactNode } from "react";

export interface SectionTitleProps extends BoxProps {
  className?: string;
  children: ReactNode;
}

export const SectionTitle = (props: SectionTitleProps) => {
  const { className = "", children } = props;
  return (
    <Box
      as="h2"
      {...props}
      className={`${className} text-3xl md:text-4xl lg:text-5xl font-medium`}
    >
      {children}
    </Box>
  );
};
