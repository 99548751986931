import {
  Button as ChakraButton,
  ButtonProps as ChakraButtonProps,
} from "@chakra-ui/react";
import React from "react";

export interface ButtonProps extends ChakraButtonProps {
  className?: string;
}

export const Button = (props: ButtonProps) => {
  const { className = "", children, ...rest } = props;
  return (
    <ChakraButton
      colorScheme="primary"
      fontWeight="medium"
      className={` bg-primary text-lg transition-all duration-200 ${className}`}
      py={5}
      px="6"
      rounded="sm"
      _hover={{
        shadow: "xl",
        color: "#20364A",
      }}
      {...rest}
    >
      {children}
    </ChakraButton>
  );
};
