import clsx from "clsx";
import React from "react";
import { SectionDescription } from "./components/SectionDescription";
import { SectionSubtitle } from "./components/SectionSubtitle";
import { SectionTitle } from "./components/SectionTitle";

export interface SectionHeaderProps {
  className?: string;
  title: string;
  subtitle?: string;
  description?: string;
  variant?: "dark" | "light";
  id?: string;
}

export const SectionHeader = (props: SectionHeaderProps) => {
  const {
    className = "",
    title,
    subtitle,
    description,
    id,
    variant = "dark",
  } = props;
  return (
    <header className={`${className} space-y-4`}>
      {subtitle ? <SectionSubtitle>{subtitle}</SectionSubtitle> : undefined}
      <SectionTitle
        lineHeight={{
          base: "normal",
        }}
        className={clsx({
          "text-white": variant === "light",
          "text-secondary": variant === "dark",
          uppercase: true,
        })}
      >
        {title}
      </SectionTitle>
      {description ? (
        <SectionDescription
          className={clsx({
            "text-gray-200": variant === "light",
            "text-terciary": variant === "dark",
          })}
        >
          {description}
        </SectionDescription>
      ) : undefined}
    </header>
  );
};
