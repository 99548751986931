import { Box } from "@chakra-ui/react";
import BackgroundImage from "gatsby-background-image";
import { getImage } from "gatsby-plugin-image";
import { convertToBgImage } from "gbimage-bridge";
import React from "react";
import { Button } from "../../Shared/Button/Button";
import { IInstagramLink } from "../hooks/useInstagramLinks";

export interface InstagramLinkProps extends IInstagramLink {
  className?: string;
}

export const InstagramLink = (props: InstagramLinkProps) => {
  const { className = "", url, image } = props;

  const firstImage = getImage(image);
  const bgImage = convertToBgImage(firstImage);

  return (
    <a
      href={url}
      target="_blank"
      rel="noopener noreferrer"
      className="flex justify-center"
    >
      <Box
        shadow="lg"
        _hover={{
          shadow: "2xl",
        }}
        height={{
          base: 290,
          md: 390,
        }}
        width={{
          base: 290,
          md: 390,
        }}
        as="article"
        className={`${className} group cursor-pointer transition-all duration-300 `}
      >
        <BackgroundImage preserveStackingContext {...bgImage} className="">
          <Box
            height={{
              base: 290,
              md: 390,
            }}
            width={{
              base: 290,
              md: 390,
            }}
            className="relative"
          >
            <Box className="overlay h-full w-full bg-gray-900 opacity-0 absolute z-0 group-hover:opacity-30 transition-all duration-300"></Box>
            <div className="content z-10 relative flex flex-col items-center justify-center h-full">
              <a
                href={url}
                target="_blank"
                rel="noopener noreferrer"
                className="mt-4 lg:opacity-0 group-hover:opacity-100 transition-all duration-500 "
              >
                <Button>OTVORIŤ ODKAZ</Button>
              </a>
            </div>
          </Box>
        </BackgroundImage>
      </Box>
    </a>
  );
};
